// 中文
const zh={
    lang:"中文",
    index1:"数字感觉",
    index2:"使用我们的数字满足您的味觉渴望",
    index3:"吃你想吃的东西",
    index4:"报告口味分数",
    index5:"检查口味分数",
    index6:"请输入饭馆名称",
    index7:"请输入菜品名称",
    index8:"请输入菜品质",
    index9:"关于数字感觉",
    index10:"提交",
    index11:"带有口味评分的食品示例",
    index12:"联系我们",
    index13:"我们热爱我们的客户，因此请随时给我们发送电子邮件",
    index14:"请给我们留言",
    index15:"版权所有",
    index16:"保留所有权利",
    index17:"由",
    index18:"提供支持",
    index19:"寻求合作",
    index20:"如果您有兴趣将食品的口味数字化或帮助您的客户找到他们喜欢的食品，请联系我们，了解有关我们如何共同努力实现共同目标的更多信息。",
}
export default zh