import axios from "axios";
import { MessageBox } from "element-ui";

const instance = axios.create({
  baseURL: "http://121.40.58.210:8082",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 正常响应逻辑
    if (response.status === 200) {
      switch (response.data.code) {
        case 200:
          // 请求成功
          return response.data;
        case 401:
          // 未授权，需要特殊处理的情况，可以直接跳转或通知用户
          window.location.href = "/login"; // 直接跳转到登录页面
          return Promise.reject({ 
            isAuthError: true, 
            message: "登录状态已过期，请重新登录。" 
          });
        default:
          // 返回错误信息给调用请求的组件处理
          return Promise.reject({
            isCommonError: true,
            message: response.data.msg || "发生未知错误，请联系管理员。"
          });
      }
    }
    // 不是200状态码的情况
    return Promise.reject(response);
  },
  function (error) {
    // 通常是网络或其他问题导致的错误
    return Promise.reject({
      isNetworkError: true,
      message: "网络错误，请稍后再试。"
    });
  }
);



// 封装 GET 请求
export function get(url, params) {
  return instance.get(url, { params });
}

// 封装 POST 请求
export function post(url, data) {
  return instance.post(url, data);
}

// 封装 PUT 请求
export function put(url, data) {
  return instance.put(url, data);
}

// 封装 DELETE 请求
export function del(url) {
  return instance.delete(url);
}

export default instance;
