// 英文
const en={
    lang:"EngLish",
    index1:"digital sense",
    index2:"Satisfy your taste cravings with our numbers",
    index3:"eat what you want",
    index4:"Report taste scores",
    index5:"Check taste score",
    index6:"Please enter the restaurant name",
    index7:"Please enter the dish name",
    index8:"Please enter dish quality",
    index9:"About digital feeling",
    index10:"submit",
    index11:"Examples of food products with taste ratings",
    index12:"contact us",
    index13:"We love our customers, so feel free to send us an email",
    index14:"please leave us a message",
    index15:"all rights reserved",
    index16:"all rights reserved",
    index17:"Depend on",
    index18:"provide support",
    index19:"Seek cooperation",
    index20:"If you are interested in digitizing the taste of food or helping your customers find food they love, please contact us to learn more about how we can work together to achieve a common goal.",
}
export default en
